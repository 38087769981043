import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import logo from 'assets/icons/logo.svg';
import { AuthContext, logout } from 'contexts/AuthContext';
import { colors, mediaQueries, routes } from 'variables';
import { MobileHeader } from './MobileHeader';

const Wrapper = styled.header`
  display: flex;
  padding: 20px 14px;
  position: sticky;
  top: 0;
  z-index: 5;
  justify-content: space-between;
  align-items: center;
  background-color: black;

  ${mediaQueries.lg} {
    background-color: black;
    background-image: linear-gradient(rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 100%);
  }
`;

const Logo = styled.img`
  width: 174px;
  height: 61px;
  cursor: pointer;
`;

const Navbar = styled.div`
  display: none;
  align-items: center;
  padding-right: 60px;
  background-color: black;
  p {
    margin: 0 0 0 36px;
    white-space: nowrap;
    cursor: pointer;
  }

  ${mediaQueries.lg} {
    display: flex;
  }
`;

const HeaderLink = styled.p`
  color: ${colors.secondary};
  :hover {
    text-decoration: underline;
  }
`;

export const Header: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const { state, dispatch } = React.useContext(AuthContext);

  const notAuthPaths = [routes.login];

  const displayNavbar =
    notAuthPaths.some(path => path.includes(location.pathname)) && location.pathname !== '/';

  return (
    <Wrapper>
      <Logo src={logo} alt="Link to DEA main page" onClick={() => history.push(routes.home)} />

      {!displayNavbar && (
        <>
          <Navbar>
            <HeaderLink onClick={() => history.push(routes.home)}>Home</HeaderLink>
            <HeaderLink onClick={() => history.push(routes.projects)}>Projects</HeaderLink>
            <HeaderLink onClick={() => history.push(routes.jury)}>Jury</HeaderLink>

            <HeaderLink style={{ textDecoration: 'none' }}>|</HeaderLink>
            <p>{state.user.fullName}</p>

            <HeaderLink
              onClick={() => {
                dispatch(logout());
                history.push(routes.login);
              }}
            >
              Logout
            </HeaderLink>
          </Navbar>
          <MobileHeader />
        </>
      )}
    </Wrapper>
  );
};
