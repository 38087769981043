import React from 'react';
import styled, { css } from 'styled-components';
import { colors, getLabelColor, getLabelText, mediaQueries } from 'variables';
import Rate from 'rc-rate';
import 'rc-rate/assets/index.css';
import { AddCommentModal } from 'components/modals/AddCommentModal';
import { useHistory } from 'react-router-dom';
import { prettifyProjectId } from 'helpers/prettifyProjectId';
import { AwardCategory, DEALabel, DEALabelObject } from 'types';
import DEAIcon from 'assets/icons/DEA-small.svg';
import ReactTooltip from 'react-tooltip';
import { AuthContext } from 'contexts/AuthContext';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  padding: 8px;

  :hover {
    background-color: ${colors.background};

    button {
      display: block;
    }
  }
`;

const Title = styled.p`
  margin: 2px 0 auto;
`;

const FlexRow = styled.div<{ voted: boolean }>`
  ${({ voted }) =>
    voted
      ? css`
          border-top: 4px solid ${colors.success};
          margin-top: -2px;
          padding-top: 8px;
        `
      : css`
          padding-top: 10px;
        `}

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SecondaryText = styled.p`
  color: ${colors.secondary};
  margin: 0;
`;

const CommentLink = styled(SecondaryText)`
  margin: 16px 0 0;
  cursor: pointer;

  :hover {
    text-decoration: underline;
  }
`;

const Commented = styled.p`
  margin: 16px 0 0;
  color: #44a12b;
  cursor: pointer;

  :hover {
    text-decoration: underline;
  }
`;

const RatingWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 20px;
  margin: 8px 0;
`;

const StyledRate = styled(Rate)<{ size: number }>`
  &.rc-rate {
    margin-top: 2px;
    font-size: ${({ size }) => size}px !important;
  }
`;

const Rating = styled.p`
  margin: 0 16px 0 0;
  font-size: 20px;
  padding-top: 6px;
`;

const ProjectImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin-bottom: 8px;
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 300px;
  margin-bottom: 8px;

  ${mediaQueries.md} {
    height: 270px;
  }

  ${mediaQueries.lg} {
    height: 220px;
  }
`;

const DetailsButton = styled.button`
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  background-color: ${colors.background};
  border: none;
  min-width: 150px;
  padding: 12px 0;
  cursor: pointer;

  transition: background-color 0.1s linear;

  :hover {
    color: black;
    background-color: white;
  }
`;

const PhotoLabelWrapper = styled.div`
  position: absolute;
  left: 0%;
  bottom: 0%;
  width: calc(100%);
  border: none;
  text-align: right;
  font-size: 0.95rem;
  text-transform: capitalize;
`;

const PhotoLabel = styled.div<{ label: DEALabel }>`
  position: relative;
  width: calc(100% - 5px);
  color: ${({ label }) => getLabelText(label)};
  border: none;
  text-align: right;
  padding-right: 5px;
  background-color: ${({ label }) => getLabelColor(label)};
  font-size: 0.95rem;
  text-transform: capitalize;
`;

const TotalScoreSection = styled.div`
  margin-top: 8px;
  padding: 8px 0;
  border-top: 2px solid ${colors.secondary};
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

interface Props {
  projectImage: string;
  projectId: number;
  projectName: string;
  commented?: boolean;
  rating: number | null;
  saveComment: (text: string, projectId: number) => Promise<void>;
  projectCategory: AwardCategory;
  saveVote: (vote: string | null, projectId: number) => Promise<void>;
  comment: string | null;
  deaScore: boolean;
  publishedTotalScore: boolean;
  totalScore: number | null;
  labels: DEALabelObject[];
}

export const ProjectCard: React.FC<Props> = ({
  projectImage,
  projectId,
  projectName,
  commented,
  rating,
  saveComment,
  projectCategory,
  saveVote,
  comment,
  deaScore,
  publishedTotalScore,
  totalScore,
  labels,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isSubmittingScore, setIsSubmittingScore] = React.useState(false);
  const history = useHistory();
  const { state } = React.useContext(AuthContext);

  const slxMember = state.user.slxMember;

  return (
    <>
      <Wrapper
        onClick={e => {
          // eslint-disable-next-line
          // @ts-ignore
          if (e.target.className && !e.target.className.includes('rc-rate')) {
            history.push(`/projects/${projectId}/${projectCategory}`);
          }
        }}
      >
        <ImageWrapper>
          <ProjectImage src={projectImage} />
          <DetailsButton>More details</DetailsButton>
          <PhotoLabelWrapper>
            {[...labels]
              .sort((a, b) => getHighestLabelWeight(b) - getHighestLabelWeight(a))
              .map(label => (
                <PhotoLabel key={label.deaLabel} label={label.deaLabel}>
                  {label.deaLabel.replaceAll('_', ' ').toLocaleLowerCase()}
                </PhotoLabel>
              ))}
          </PhotoLabelWrapper>
        </ImageWrapper>

        <FlexRow voted={!!rating}>
          <SecondaryText>{prettifyProjectId(projectId)}</SecondaryText>
          {deaScore && (
            <>
              <img src={DEAIcon} data-tip="DEA Selection" />
              <ReactTooltip />
            </>
          )}
        </FlexRow>

        <Title>{projectName}</Title>

        {slxMember ? (
          <div />
        ) : (
          <>
            {' '}
            {commented ? (
              <Commented
                onClick={e => {
                  e.stopPropagation();
                  e.preventDefault();
                  setIsOpen(true);
                }}
              >
                Commented
              </Commented>
            ) : (
              <CommentLink
                onClick={e => {
                  e.stopPropagation();
                  e.preventDefault();
                  setIsOpen(true);
                }}
              >
                Add comment
              </CommentLink>
            )}
            <RatingWrapper>
              <Rating>{rating || 0}</Rating>
              <StyledRate
                size={25}
                value={rating === null ? 0 : rating}
                disabled
                onChange={async score => {
                  if (rating === score) {
                    return;
                  }
                  setIsSubmittingScore(true);
                  const scoreToSave = score !== 0 ? score.toString() : null;
                  await saveVote(scoreToSave, projectId);
                  setIsSubmittingScore(false);
                }}
              />
            </RatingWrapper>
          </>
        )}

        {publishedTotalScore && (
          <TotalScoreSection>
            <SecondaryText>Total score:</SecondaryText>
            <Rating style={{ margin: 0, fontSize: '28px' }}>
              {totalScore
                ? (Math.round(totalScore * 10) / 10).toFixed(1).toString().padEnd(3, '0')
                : '0.0'}
            </Rating>
          </TotalScoreSection>
        )}
      </Wrapper>
      <AddCommentModal
        isOpen={isOpen}
        closeModal={() => setIsOpen(false)}
        projectId={projectId}
        saveComment={saveComment}
        projectName={projectName}
        initialValue={comment}
      />
    </>
  );
};

function getHighestLabelWeight(label: DEALabelObject) {
  if (label.deaLabel === DEALabel.WinnerOfTheYear) return 10;
  if (label.deaLabel === DEALabel.GoldPrize) return 9;
  if (label.deaLabel === DEALabel.SilverPrize) return 8;
  if (label.deaLabel === DEALabel.BronzePrize) return 7;
  if (label.deaLabel === DEALabel.SpecialRecognition) return 6;
  if (label.deaLabel === DEALabel.HonorableMention) return 5;
  if (label.deaLabel === DEALabel.EmergingDesigner) return 4;
  if (label.deaLabel === DEALabel.SolaruxChoice) return 3;
  return 2;
}
