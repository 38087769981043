export interface ProjectImage {
  credits: string | null;
  image: string;
}

export enum AwardCategory {
  ProductDesign = "PRODUCT_DESIGN",
  ArchitecturalDesign = "ARCHITECTURAL_DESIGN",
  ResponsiveDesign = "RESPONSIVE_DESIGN",
  UniversalDesign = "UNIVERSAL_DESIGN",
}

export enum ParticipantCategory {
  IndividualStudent = "INDIVIDUAL_STUDENT",
  TeamOfStudents = "TEAM_OF_STUDENTS",
  IndividualProfessional = "INDIVIDUAL_PROFESSIONAL",
  CompanyGreaterThan10Employees = "COMPANY_GREATER_THAN_10_EMPLOYEES",
  CompanyGreater11100Employees = "COMPANY_GREATER_11_100_EMPLOYEES",
  CompanyGreaterThan100Employees = "COMPANY_GREATER_THAN_100_EMPLOYEES",
}

export enum DEALabel {
  WinnerOfTheYear = "WINNER_OF_THE_YEAR",
  SpecialRecognition = "SPECIAL_RECOGNITION",
  GoldPrize = "GOLD_PRIZE",
  SilverPrize = "SILVER_PRIZE",
  BronzePrize = "BRONZE_PRIZE",
  HonorableMention = "HONORABLE_MENTION",
  Finalist = "FINALIST",
  EmergingDesigner = "EMERGING_DESIGNER",
  SolaruxChoice = "SOLARUX_CHOICE",
}

export interface DEALabelObject {
  deaLabel: DEALabel;
  deaLabelCategory: string;
}

export interface ProjectListEntry {
  id: number;
  projectName: string;
  images: ProjectImage[];
  awardCategory: AwardCategory[];
  participantCategory: ParticipantCategory;
  score: number | null;
  comments: number;
  commentContent: string | null;
  deaScore: boolean;
  total: number | null;
  labels: DEALabelObject[];
  projectEdition: string | null;
}

export interface ProjectSingle extends ProjectListEntry {
  client: string | null;
  company: string | null;
  country: string | null;
  description: string | null;
  designTeam: string | null;
  email: string | null;
  location: string | null;
  website: string | null;
  nextProjectId: string | null;
}

export interface JuryMember {
  uid: string;
  fullName: string;
  email: string;
  avatar: string | null;
  bio: string | null;
  juryAssignedProjectCategories: AwardCategory[];
  juryCompanyName: string | null;
}
