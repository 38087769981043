import { JuryCard } from 'components/jury/JuryCard';
import React from 'react';

import styled from 'styled-components';
import { apiBase, mediaQueries } from 'variables';
import { apiClient } from 'helpers/APIClient';
import { AwardCategory, JuryMember } from 'types';
import { toast } from 'react-toastify';
import { toastSettings } from 'helpers/toastSettings';
import { Loader } from 'components/common/Loader';

const Wrapper = styled.main`
  width: 100%;
  padding: 36px 25px;

  ${mediaQueries.lg} {
    padding: 36px 50px;
  }

  ${mediaQueries.xl} {
    padding: 36px 88px;
  }
`;

const JuryGrid = styled.div`
  display: grid;
  justify-content: center;
  justify-items: center;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 16px;
  column-gap: 16px;

  ${mediaQueries.sm} {
    grid-template-columns: repeat(3, 1fr);
  }

  ${mediaQueries.md} {
    grid-template-columns: repeat(4, 1fr);
  }

  ${mediaQueries.lg} {
    grid-template-columns: repeat(5, 1fr);
  }

  ${mediaQueries.xl} {
    grid-template-columns: repeat(6, 1fr);
  }

  ${mediaQueries.xxl} {
    grid-template-columns: repeat(7, 1fr);
  }
`;

export const JuryList: React.FC = () => {
  const [jury, setJury] = React.useState<JuryMember[]>([]);
  const [isFetching, setIsFetching] = React.useState(false);

  React.useEffect(() => {
    setIsFetching(true);

    apiClient
      .get(`${apiBase}jury/`)
      .then(({ data }) => setJury(data))
      .then(() => setIsFetching(false))
      .catch(() => toast('Failed to fetch jury', toastSettings));
  }, []);

  if (isFetching) return <Loader />;

  return (
    <Wrapper>
      <h1>Jury</h1>
      <br />
      <h3>Architectural design</h3>
      <JuryGrid>
        {jury
          .filter(j => j.juryAssignedProjectCategories.includes(AwardCategory.ArchitecturalDesign))
          .map(j => (
            <JuryCard key={j.uid} jury={j} />
          ))}
      </JuryGrid>

      <h3>Product design</h3>
      <JuryGrid>
        {jury
          .filter(j => j.juryAssignedProjectCategories.includes(AwardCategory.ProductDesign))
          .map(j => (
            <JuryCard key={j.uid} jury={j} />
          ))}
      </JuryGrid>

      <h3>Responsive design</h3>
      <JuryGrid>
        {jury
          .filter(j => j.juryAssignedProjectCategories.includes(AwardCategory.ResponsiveDesign))
          .map(j => (
            <JuryCard key={j.uid} jury={j} />
          ))}
      </JuryGrid>

      <h3>Universal design</h3>
      <JuryGrid>
        {jury
          .filter(j => j.juryAssignedProjectCategories.includes(AwardCategory.UniversalDesign))
          .map(j => (
            <JuryCard key={j.uid} jury={j} />
          ))}
      </JuryGrid>
    </Wrapper>
  );
};
