import React from 'react';
import styled from 'styled-components';
import { colors, mediaBase } from 'variables';
import 'rc-rate/assets/index.css';

import { JuryMember } from 'types';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;

  padding: 8px;

  :hover {
    background-color: ${colors.background};

    button {
      visibility: initial;
    }
  }
`;

const PrimaryText = styled.p`
  margin: 2px 0 0;
`;

const SecondaryText = styled.p`
  color: ${colors.secondary};
  margin-top: 8px;
`;

const JuryImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
  margin-bottom: 8px;
`;

interface Props {
  jury: JuryMember;
}

export const JuryCard: React.FC<Props> = ({ jury }) => {
  return (
    <Wrapper>
      <JuryImage src={`${mediaBase}${jury.avatar}`} />
      <PrimaryText>{jury.fullName}</PrimaryText>
      <SecondaryText>{jury.juryCompanyName || '-'}</SecondaryText>
    </Wrapper>
  );
};
